import React from "react";

import Layout from "../../components/Layout";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import "../../styles/SelectionPage.scss";

import img01 from "../../content/tutorial/images/Bricklayer-amico.svg";
import img02 from "../../content/tutorial/images/Completed steps-amico.svg";

export default function TutorialsIndex() {
  return (
    <Layout title="Tutoriels" noscroll>
      <div className="features-root">
        <h1>Tutoriels</h1>
        <div className="features tutorial-choice">
          <a href="/fr/tutorial/a-to-z/" className="tutorial-block">
            <h2>Concevoir une étude de A à Z</h2>
            <div className="tutorial-block-desc">
              <img src={img01} alt="Concevoir une étude de A à Z" />
              <p>
                Ce tutoriel est destiné aux utilisateurs souhaitant mettre en
                place une étude et construire un eCRF à partir de zéro.
              </p>
              <PlayCircleOutlineOutlinedIcon />
            </div>
          </a>
          <a href="/fr/tutorial/using-dotter/" className="tutorial-block">
            <h2>Utiliser Dotter.science au quotidien</h2>
            <div className="tutorial-block-desc">
              <img src={img02} alt="Utiliser Dotter.science au quotidien" />
              <p>
                Si vous êtes un utilisateur d'une étude déjà créée, ce tutoriel
                va vous apprendre à utiliser l'eCRF mis en place.
              </p>
              <PlayCircleOutlineOutlinedIcon />
            </div>
          </a>
        </div>
      </div>
    </Layout>
  );
}
